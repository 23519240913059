import { useEffect, useState } from "react";
import Banner from "./banner-section";
import Navbar from "./navbar";
import Footer from "./footer";
import Loading from "./loading";
import { Helmet } from "react-helmet";

export default function Faq() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>FAQs</title>
        <meta
          name="description"
          content="Ignite is renowned for manufacturing and exporting a varied range of tiles with exceptional durability, innovative designs and aesthetic appeal."
        />
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Navbar modalBg="modalbg" />
          <main id="faq">
            <Banner data="FAQS" image="previews/7.jpg" />
            <div className="section-2 padding-top">
              <div className="row">
                <div className="col-lg-8 col-md-10 mx-auto">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What kind of tile does Ignite offer, and how do I know
                          which type is best for my kitchen application?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <h5>
                            Ignite is a well-known tile brand offering a vast
                            tile collection with exceptional quality. We offer-
                          </h5>
                          <ul>
                            <li>Mosaic Tiles</li>
                            <li>Porcelain GVT/PGVT Tiles</li>
                            <li>Unglazed Porcelain Tiles</li>
                            <li>Outdoor Tiles</li>
                            <li>Sintered Stone</li>
                            <li>Porcelain Slab Tiles</li>
                            <li>Swimming Pool Tiles</li>
                            <li>Subway Tiles</li>
                            
                            <li>Ceramic Floor & Wall Tiles</li>
                            
                            
                          </ul>
                          <p>
                            Different mosaics can be placed in various places,
                            such as kitchen backsplashes, swimming pools,
                            showers, floors, walls, etc.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          Where can I use porcelain tiles? Is it suitable for the
                          office?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>
                          Porcelain Tile is a versatile material. You can use
                          porcelain tiles for flooring, walls, kitchen
                            countertops, outdoor patios, and hallways. <b>Ignite<sup>&#174;</sup> </b>
                            offers excellent quality ceramic tiles that
                            perfectly balance aesthetics and functionality.
                          </p>
                          <p>
                            The endless design option, exceptional durability,
                            resistance properties, and easy maintenance make
                            them an ideal choice for office space.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          In which sizes do Ignite offer tiles?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Our vast tile collection has different sizes: from
                            standard mosaics to Unglazed Porcelain Tile and large slab tiles and more. If your architectural requirements
                            require customised tile sizes, Ignite has you
                            covered!
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Can I use porcelain tiles for wall and floor
                          installation in outdoor areas?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Add more elegance to your outdoor areas with
                            Ignite’s porcelain tiles. Porcelain tiles have a low
                            water absorption rate, making them
                            moisture-resistant. In addition to being
                            UV-resistant, these tiles do not fade under
                            sunlight, which makes them perfect for your outdoor
                            areas like porches, garden areas, bathrooms, and
                            swimming pool decks.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          What are the advantages of using Porcelain GVT & PGVT tiles for
                          my home?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>
                            GVT (glazed vitrified tiles) & PGVT (polished glazed
                            vitrified tiles) are a type of porcelain tile made
                            by pressing clay and other materials together at
                            high temperatures to create a hard, non-porous
                            surface. PGVT has a polished finish, whereas GVT has
                            a matt finish. These options are affordable,
                            durable, and stain-resistant, making them excellent
                            for floors, countertops, and backsplashes.
                          </p>
                          <p>
                            <b>Ignite<sup>&#174;</sup></b> offers porcelain GVT/PGVT tiles in a wide
                            array of colours and designs that provide a
                            luxurious look in any home.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </>
      )}
    </>
  );
}
