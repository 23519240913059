import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Banner from "./banner-section";
import { Helmet } from "react-helmet";
import Footer from "./footer";
import Navbar from "./navbar";
import Loading from "./loading";

const Blogs = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Blogs</title>
            </Helmet>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Navbar modalBg="modalbg" />
                    <main id="blogs">
                        <Banner data="Blogs" image="preview/preview_7_800x1200_9_s.jpg" />

                        <section className="section-2 padding-top">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <div className="bg-color">
                                            <div className="row">
                                                <div className="col-lg-6 d-flex flex-column justify-content-center">
                                                    <div className="image-wrapper">
                                                        <img src="preview/mosaic.jpg" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 ">
                                                    <small>01 Jan 2023</small>
                                                    <h2 className="h2_heading my-4">Mosaic Tiles: A Complete Guide</h2>
                                                    <p>
                                                        A mosaic tile can enhance the aesthetics of your home in many ways, from wall cladding to swimming pool flooring. Learn everything about beautiful mosaic tiles. Why do homeowners wants them in their home decor.
                                                    </p>
                                                    <Link to="/mosaic-tiles-a-complete-guide"><button className="blog-button">Read More</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-10 mt-5">
                                        <div className="bg-color">
                                            <div className="row">

                                                <div className="col-lg-6">
                                                    <small>01 Jan 2023</small>
                                                    <h2 className="h2_heading my-4">Reasons To Choose Porcelain Tiles For your Home </h2>
                                                    <p>
                                                        The market is full of different kinds of flooring materials. But that cannot defame porcelain tiles’ efficacy and remarkable benefits. Here are ten reasons to choose porcelain tiles for your floors and walls in your home.
                                                    </p>
                                                    <Link to="/reason-to-choose-porcelain-tiles-for-your-home"><button className="blog-button">Read More</button></Link>
                                                </div>
                                                <div className="col-lg-6 mt-lg-0 mt-3 d-flex flex-column justify-content-center">
                                                    <div className="image-wrapper">
                                                    <img src="previews/6.jpg"
                                    alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-10 mt-5">
                            <div className="bg-color">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="image-wrapper">
                                            <img src="https://images.unsplash.com/photo-1512486130939-2c4f79935e4f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-4">
                                        <small>01 Jan 2023</small>
                                        <h2 className="h2_heading my-4">Blog Title</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate dolores, odio praesentium eum molestiae tempora nesciunt natus corrupti blanditiis aliquid?
                                        </p>
                                        <Link to="/blog-inside"><button className="blog-button">Read More</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                                </div>
                            </div>
                        </section>
                    </main>
                    <Footer />
                </>
            )}
        </>
    )
}

export default Blogs;