import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Banner from "./banner-section";
import Footer from "./footer";
import Latest_Insights from "./get-latest-insights";
import Loading from "./loading";
import Navbar from "./navbar";
import { send } from 'emailjs-com';

export default function Download() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  // Mosaic Data
  const mosaic =
    [
      {
        "name": "Polished Glazed Porcelain Mosaic",
        "url": "download/mosaic/mosaic.jpg",
        "pdf": "/download/mosaic/Polished_GlazedPorcelain_Mosaic.pdf"
      },
      {
        "name": "Glazed Porcelain Mosaic",
        "url": "download/mosaic/mosaic.jpg",
        "pdf": "/download/mosaic/Glazed_Porcelain_Mosaic.pdf"
      },
      {
        "name": "Unglazed Porcelain Mosaic",
        "url": "download/mosaic/mosaic.jpg",
        "pdf": "/download/mosaic/Unglazed_PorcelainMosaic.pdf"
      },
    ]

  // porcelainTiles data

  const porcelainTiles =
    [
      {
        "name": "Fullbody Unglazed Porcelain Tiles - 1",
        "url": "download/Porcelain-tiles/Fullbody-Unglazed_PorcelainTiles_60x120cm_01.png",
        "pdf": "/download/Porcelain-tiles/Fullbody-Unglazed_PorcelainTiles_60x120cm_01.pdf"
      },
      {
        "name": "Fullbody Unglazed Porcelain Tiles - 2",
        "url": "download/Porcelain-tiles/Fullbody-Unglazed_PorcelainTiles_60x120cm_02.png",
        "pdf": "/download/Porcelain-tiles/Fullbody-Unglazed_PorcelainTiles_60x120cm_02.pdf"
      },
      {
        "name": "Pastel Colour",
        "url": "download/Porcelain-tiles/Pastel_Colour_60x120cm.png",
        "pdf": "/download/Porcelain-tiles/Pastel_Colour_60x120cm.pdf"
      },
      {
        "name": "3D",
        "url": "download/Porcelain-tiles/3D60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/3D60x120cm.pdf"
      },
      {
        "name": "Bookmatch",
        "url": "download/Porcelain-tiles/BOOKMATCH-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/BOOKMATCH-60x120cm.pdf"
      },
      {
        "name": "Endmatch",
        "url": "download/Porcelain-tiles/ENDMATCH-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/ENDMATCH-60x120cm.pdf"
      },
      {
        "name": "Glossy",
        "url": "download/Porcelain-tiles/GLOSSY-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/GLOSSY-60x120cm.pdf"
      },
      {
        "name": "High Glossy",
        "url": "download/Porcelain-tiles/HIGH-GLOSSY-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/HIGH-GLOSSY-60x120cm.pdf"
      },
      {
        "name": "Matt Carving",
        "url": "download/Porcelain-tiles/MATT-CARVING-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/MATT-CARVING-60x120cm.pdf"
      },
      {
        "name": "PGVT",
        "url": "download/Porcelain-tiles/POLISHED-GLAZED-VITRIFID-TILE-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/POLISHED-GLAZED-VITRIFID-TILE-60x120cm.pdf"
      },
      {
        "name": "Rustic",
        "url": "download/Porcelain-tiles/RUSTIC-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/RUSTIC-60x120cm.pdf"
      },
      {
        "name": "Satvario",
        "url": "download/Porcelain-tiles/SATVARIO-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/SATVARIO-60x120cm.pdf"
      },
      {
        "name": "Wooden",
        "url": "download/Porcelain-tiles/WOODEN-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/WOODEN-60x120cm.pdf"
      },
    ]

  // Porcelain Slad
  const porcelainSlad =
    [
      {
        "name": "800x1600 MM",
        "url": "download/Porcelain-Slab/800x1600mm-9mmSlab.jpg",
        "pdf": "/download/Porcelain-Slab/800x1600mm-9mmSlab.pdf"
      },
      {
        "name": "1200x2400 MM",
        "url": "download/Porcelain-Slab/1200x2400mm-9mm-Slab.jpg",
        "pdf": "/download/Porcelain-Slab/1200x2400mm-9mm-Slab.pdf"
      },
      {
        "name": "1200x1200 MM",
        "url": "download/Porcelain-Slab/1200X1200mm-9mm-Slab.jpg",
        "pdf": "/download/Porcelain-Slab/1200X1200mm-9mm-Slab.pdf"
      },
      {
        "name": "1800x1200 MM",
        "url": "download/Porcelain-Slab/1800X1200mm-9mm-Slab.jpg",
        "pdf": "/download/Porcelain-Slab/1800X1200mm-9mm-Slab.pdf"
      },

      {
        "name": "1600x3200 MM",
        "url": "download/Porcelain-Slab/1600x3200.jpg",
        "pdf": "/download/Porcelain-Slab/1600x3200.pdf"
      },
    ]

  // Outdoor
  const outdoor =
    [
      {
        "name": "Outdoor",
        "url": "download/Outdoor-20mm/Outdoor-20mm.jpg",
        "pdf": "/download/Outdoor-20mm/Outdoor-20mm.pdf"
      },
      {
        "name": "600x600 MM",
        "url": "download/Outdoor-20mm/600x600-outdoor.jpg",
        "pdf": "/download/Outdoor-20mm/600x600-outdoor.pdf"
      },
    ]

  // Sintered Stone
  const sintered =
    [
      {
        "name": "800x2400 MM",
        "url": "download/Sintered-Stone/800x2400mm-15mm-Sintered_Stone.jpg",
        "pdf": "/download/Sintered-Stone/800x2400mm-15mm-Sintered_Stone.pdf"
      },
      {
        "name": "1200x1600 MM",
        "url": "download/Sintered-Stone/1200-X-1600mm-15mm-Sintered-Stone.jpg",
        "pdf": "/download/Sintered-Stone/1200-X-1600mm-15mm-Sintered-Stone.pdf"
      },
    ]


  const [formData, setFormData] = useState({
    product_name: '',
    your_name: '',
    comp_name: '',
    address: '',
    city: '',
    state: '',
    country: '',
    contact: '',
    email: ''
  });
  const [modalData, setModalData] = useState(null);
  const [productName, setProductName] = useState(null);
  const handleButtonClick = (product) => {
    setModalData(product.pdf);
    setProductName(product.name)
  };
  const onSubmit = (event, props) => {
    event.preventDefault();
    fetch("https://email.ignitemosaic.com/product-data",{
      method:"post",
      headers:{
          "Content-Type":"application/json"
      },
      body:JSON.stringify({
      your_name: formData.your_name,
      product_name:productName,

      comp_name: formData.comp_name,
      address: formData.address,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      contact: formData.contact,
      from_email: formData.email,
      })
  }).then(res=>res.json())
  .then(data=>{
      
        window.open(modalData, '_self');
  }).catch(err=>{
      console.log(err)
  });
    // const templateParams = {
        
    //   your_name: formData.your_name,
    //   product_name:productName,

    //   comp_name: formData.comp_name,
    //   address: formData.address,
    //   city: formData.city,
    //   state: formData.state,
    //   country: formData.country,
    //   contact: formData.contact,
    //   from_email: formData.email,
    // };
    // console.log(templateParams);
    // send(
    //   'service_eh3ymv8',
    //   'template_b1rk2ch',
    //   templateParams,
    //   'TqdoLKns8KVRsxZKu'
    // )
    //   .then((response) => {
    //     console.log('SUCCESS!', response.status, response.text);
    //     window.open(modalData, '_self');
    //   })
    //   .catch((err) => {
    //     console.log('FAILED...', err);
    //   });


    

    // const listElement = useRef(null);

    // handleCloseModal()
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Products</title>
        <meta
          name="description"
          content="Ignite is renowned for manufacturing and exporting a varied range of tiles with exceptional durability, innovative designs and aesthetic appeal."
        />
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Navbar modalBg="modalbg" />
          <main id="download">
            <Banner
              data="Products"
              image="previews/9.jpg"
            />






            <section className="section-2 padding-top">
              <h2 className="h2_heading text-center mb-5">
                DOWNLOAD LATEST CATALOGUE
              </h2>
              <div className="container">
                <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link px-lg-5 py-md-2 px-3 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#mosaic" type="button" role="tab" aria-controls="mosaic" aria-selected="true">Mosaic</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link px-lg-5 py-md-2 px-3" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#porcelain-tiles" type="button" role="tab" aria-controls="porcelain-tiles" aria-selected="false">Porcelain Tiles</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link px-lg-5 py-md-2 px-3" id="pills-third-tab" data-bs-toggle="pill" data-bs-target="#porcelain-slab" type="button" role="tab" aria-controls="porcelain-slab" aria-selected="false">Porcelain Slab</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link px-lg-5 py-md-2 px-3" id="pills-four-tab" data-bs-toggle="pill" data-bs-target="#outdoor" type="button" role="tab" aria-controls="outdoor" aria-selected="false">Outdoor</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link px-lg-5 py-md-2 px-3" id="pills-five-tab" data-bs-toggle="pill" data-bs-target="#sintered-stone" type="button" role="tab" aria-controls="sintered-stone" aria-selected="false">Sintered Stone</button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  {/* ======================================================================================================================================================================================================== */}

                  {/* MOSAIC */}

                  {/* ====================================================================================================================================================================== */}

                  <div className="tab-pane fade show active" id="mosaic" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                    <div className="row d-flex justify-content-center">

                      {
                        mosaic.map((product) => {
                          return (
                            <>
                              <div className="col-lg-4 col-md-6 mb-5">
                                <div className="wrapper">
                                  <div className="image-wrapper">
                                    <img src={product.url} alt="" />
                                  </div>
                                  <div className="text-center">
                                    <h6>{product.name}</h6>



                                    {/* Button trigger modal  */}
                                    <button className="download-btn" data-bs-toggle="modal" data-bs-target="#mosaicModal" onClick={() => handleButtonClick(product)} >
                                      Download
                                    </button>

                                    {/* Modal  */}
                                    <div className="modal fade" id="mosaicModal" tabindex="-1" aria-labelledby="mosaicModalLabel" aria-hidden="true">
                                      <div className="modal-dialog modal-dialog modal-xl modal-fullscreen-lg-down modal-dialog-centered">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="mosaicModalLabel">Fill This Form   </h1>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                          <div className="container">
                                              <form onSubmit={(event) => onSubmit(event, { pdf: product.pdf })} >
                                                <div className="row">
                                                <div className="col-md-12 mb-4">
                                                    <div className="form-group text-start">
                                                        
                                                      <label className="form-label">Product Detail</label>
                                                      <input
                                                        className="form-control"
                                                        name="product_name"
                                                        type="text"
                                                        placeholder=""
                                                        
                                                        value={productName}
                                                        readOnly
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start">
                                                        
                                                      <label className="form-label">Your Name*</label>
                                                      <input
                                                        className="form-control"
                                                        name="your_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.your_name}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start ">
                                                      <label className="form-label">Company Name</label>
                                                      <input
                                                        className="form-control"
                                                        name="comp_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.comp_name}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start mt-4">
                                                  <label className="form-label">Address</label>
                                                  <input
                                                    className="form-control"
                                                    name="address"
                                                    type="text"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.address}
                                                  />
                                                </div>

                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">City*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="city"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.city}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">State*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="state"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.state}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Country*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="country"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.country}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Email id*</label>
                                                      <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.email}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>




                                                <div className="form-group text-start my-4">
                                                  <label className="form-label">Contact No.*</label>
                                                  <input
                                                    className="form-control"
                                                    type="number"
                                                    name="contact"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.contact}
                                                    required
                                                  />
                                                </div>


                                                <div className="text-center">
                                                  <button type="submit" className="mt-4 form-button">Send</button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                  </div>
                                </div>
                              </div>
                            </>
                          )

                        })
                      }


                    </div>
                  </div>
                  {/* ======================================================================================================================================================================================================== */}

                  {/* PORCELAIN TILES */}

                  {/* ====================================================================================================================================================================== */}
                  <div className="tab-pane fade" id="porcelain-tiles" role="tabpanel" aria-labelledby="pills-second-tab" tabIndex="0">
                    <div className="row d-flex justify-content-center">


                      {
                        porcelainTiles.map((product) => {
                          return (
                            <>
                              <div className="col-lg-4 col-md-6 mb-5">
                                <div className="wrapper">
                                  <div className="image-wrapper">
                                    <img src={product.url} alt="" />
                                  </div>
                                  <div className="text-center">
                                    <h6>{product.name}</h6>



                                    {/* Button trigger modal  */}
                                    <button className="download-btn" data-bs-toggle="modal" data-bs-target="#downloadModal" onClick={() => handleButtonClick(product)} >
                                      Download
                                    </button>

                                    {/* Modal  */}
                                    <div className="modal fade" id="downloadModal" tabindex="-1" aria-labelledby="downloadModalLabel" aria-hidden="true">
                                      <div className="modal-dialog modal-dialog modal-xl modal-fullscreen-lg-down modal-dialog-centered">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="downloadModalLabel">Fill This Form </h1>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <div className="container">
                                              <form onSubmit={(event) => onSubmit(event, { pdf: product.pdf })} >
                                                <div className="row">
                                                <div className="col-md-12 mb-4">
                                                    <div className="form-group text-start">
                                                        
                                                      <label className="form-label">Product Detail</label>
                                                      <input
                                                        className="form-control"
                                                        name="product_name"
                                                        type="text"
                                                        placeholder=""
                                                        
                                                        value={productName}
                                                        readOnly
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start">
                                                      <label className="form-label">Your Name*</label>
                                                      <input
                                                        className="form-control"
                                                        name="your_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.your_name}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start ">
                                                      <label className="form-label">Company Name</label>
                                                      <input
                                                        className="form-control"
                                                        name="comp_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.comp_name}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start mt-4">
                                                  <label className="form-label">Address</label>
                                                  <input
                                                    className="form-control"
                                                    name="address"
                                                    type="text"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.address}
                                                  />
                                                </div>

                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">City*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="city"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.city}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">State*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="state"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.state}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Country*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="country"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.country}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Email id*</label>
                                                      <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.email}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>




                                                <div className="form-group text-start my-4">
                                                  <label className="form-label">Contact No.*</label>
                                                  <input
                                                    className="form-control"
                                                    type="number"
                                                    name="contact"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.contact}
                                                    required
                                                  />
                                                </div>


                                                <div className="text-center">
                                                  <button type="submit" className="mt-4 form-button">Send</button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                  </div>
                                </div>
                              </div>
                            </>
                          )

                        })
                      }





                    </div>
                  </div>




                  {/* ======================================================================================================================================================================================================== */}

                  {/* PORCELAIN SLAB */}

                  {/* ====================================================================================================================================================================== */}



                  <div className="tab-pane fade" id="porcelain-slab" role="tabpanel" aria-labelledby="pills-third-tab" tabIndex="0">
                    <div className="row d-flex justify-content-center">

                      {
                        porcelainSlad.map((product) => {
                          return (
                            <>
                              <div className="col-lg-4 col-md-6 mb-5">
                                <div className="wrapper">
                                  <div className="image-wrapper">
                                    <img src={product.url} alt="" style={{backgroundColor: '#000'}} />
                                  </div>
                                  <div className="text-center">
                                    <h6>{product.name}</h6>



                                    {/* Button trigger modal  */}
                                    <button className="download-btn" data-bs-toggle="modal" data-bs-target="#porcelainSladModal" onClick={() => handleButtonClick(product)} >
                                      Download
                                    </button>

                                    {/* Modal  */}
                                    <div className="modal fade" id="porcelainSladModal" tabindex="-1" aria-labelledby="porcelainSladModalLabel" aria-hidden="true">
                                      <div className="modal-dialog modal-dialog modal-xl modal-fullscreen-lg-down modal-dialog-centered">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="porcelainSladModalLabel">Fill This Form    </h1>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <div className="container">
                                              <form onSubmit={(event) => onSubmit(event, { pdf: product.pdf })} >
                                                <div className="row">
                                                <div className="col-md-12 mb-4">
                                                    <div className="form-group text-start">
                                                        
                                                      <label className="form-label">Product Detail</label>
                                                      <input
                                                        className="form-control"
                                                        name="product_name"
                                                        type="text"
                                                        placeholder=""
                                                        
                                                        value={productName}
                                                        readOnly
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start">
                                                      <label className="form-label">Your Name*</label>
                                                      <input
                                                        className="form-control"
                                                        name="your_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.your_name}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start ">
                                                      <label className="form-label">Company Name</label>
                                                      <input
                                                        className="form-control"
                                                        name="comp_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.comp_name}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start mt-4">
                                                  <label className="form-label">Address</label>
                                                  <input
                                                    className="form-control"
                                                    name="address"
                                                    type="text"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.address}
                                                  />
                                                </div>

                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">City*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="city"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.city}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">State*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="state"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.state}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Country*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="country"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.country}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Email id*</label>
                                                      <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.email}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>




                                                <div className="form-group text-start my-4">
                                                  <label className="form-label">Contact No.*</label>
                                                  <input
                                                    className="form-control"
                                                    type="number"
                                                    name="contact"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.contact}
                                                    required
                                                  />
                                                </div>


                                                <div className="text-center">
                                                  <button type="submit" className="mt-4 form-button">Send</button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                  </div>
                                </div>
                              </div>
                            </>
                          )

                        })
                      }


                    </div>
                  </div>

                  {/* ======================================================================================================================================================================================================== */}

                  {/* OUTDOOR */}

                  {/* ====================================================================================================================================================================== */}


                  <div className="tab-pane fade" id="outdoor" role="tabpanel" aria-labelledby="pills-four-tab" tabIndex="0">
                    <div className="row d-flex justify-content-center">
                      {
                        outdoor.map((product) => {
                          return (
                            <>
                              <div className="col-lg-4 col-md-6 mb-5">
                                <div className="wrapper">
                                  <div className="image-wrapper">
                                    <img src={product.url} alt="" />
                                  </div>
                                  <div className="text-center">
                                    <h6>{product.name}</h6>



                                    {/* Button trigger modal  */}
                                    <button className="download-btn" data-bs-toggle="modal" data-bs-target="#outdoorModal" onClick={() => handleButtonClick(product)} >
                                      Download
                                    </button>

                                    {/* Modal  */}
                                    <div className="modal fade" id="outdoorModal" tabindex="-1" aria-labelledby="outdoorModalLabel" aria-hidden="true">
                                      <div className="modal-dialog modal-dialog modal-xl modal-fullscreen-lg-down modal-dialog-centered">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="outdoorModalLabel">Fill This Form  </h1>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <div className="container">
                                              <form onSubmit={(event) => onSubmit(event, { pdf: product.pdf })} >
                                                <div className="row">
                                                <div className="col-md-12 mb-4">
                                                    <div className="form-group text-start">
                                                        
                                                      <label className="form-label">Product Detail</label>
                                                      <input
                                                        className="form-control"
                                                        name="product_name"
                                                        type="text"
                                                        placeholder=""
                                                        
                                                        value={productName}
                                                        readOnly
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start">
                                                      <label className="form-label">Your Name*</label>
                                                      <input
                                                        className="form-control"
                                                        name="your_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.your_name}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start ">
                                                      <label className="form-label">Company Name</label>
                                                      <input
                                                        className="form-control"
                                                        name="comp_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.comp_name}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start mt-4">
                                                  <label className="form-label">Address</label>
                                                  <input
                                                    className="form-control"
                                                    name="address"
                                                    type="text"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.address}
                                                  />
                                                </div>

                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">City*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="city"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.city}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">State*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="state"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.state}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Country*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="country"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.country}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Email id*</label>
                                                      <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.email}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>




                                                <div className="form-group text-start my-4">
                                                  <label className="form-label">Contact No.*</label>
                                                  <input
                                                    className="form-control"
                                                    type="number"
                                                    name="contact"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.contact}
                                                    required
                                                  />
                                                </div>


                                                <div className="text-center">
                                                  <button type="submit" className="mt-4 form-button">Send</button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                  </div>
                                </div>
                              </div>
                            </>
                          )

                        })
                      }

                    </div>
                  </div>

                  {/* ======================================================================================================================================================================================================== */}

                  {/* SINTERED STONE */}

                  {/* ====================================================================================================================================================================== */}



                  <div className="tab-pane fade" id="sintered-stone" role="tabpanel" aria-labelledby="pills-five-tab" tabIndex="0">
                    <div className="row d-flex justify-content-center">
                      {
                        sintered.map((product) => {
                          return (
                            <>
                              <div className="col-lg-4 col-md-6 mb-5">
                                <div className="wrapper">
                                  <div className="image-wrapper">
                                    <img src={product.url} alt="" />
                                  </div>
                                  <div className="text-center">
                                    <h6>{product.name}</h6>



                                    {/* Button trigger modal  */}
                                    <button className="download-btn" data-bs-toggle="modal" data-bs-target="#sinteredModal" onClick={() => handleButtonClick(product)} >
                                      Download
                                    </button>

                                    {/* Modal  */}
                                    <div className="modal fade" id="sinteredModal" tabindex="-1" aria-labelledby="sinteredModalLabel" aria-hidden="true">
                                      <div className="modal-dialog modal-dialog modal-xl modal-fullscreen-lg-down modal-dialog-centered">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="sinteredModalLabel">Fill This Form </h1>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <div className="container">
                                              <form onSubmit={(event) => onSubmit(event, { pdf: product.pdf })} >
                                                <div className="row">
                                                <div className="col-md-12 mb-4">
                                                    <div className="form-group text-start">
                                                        
                                                      <label className="form-label">Product Detail</label>
                                                      <input
                                                        className="form-control"
                                                        name="product_name"
                                                        type="text"
                                                        placeholder=""
                                                        
                                                        value={productName}
                                                        readOnly
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start">
                                                      <label className="form-label">Your Name*</label>
                                                      <input
                                                        className="form-control"
                                                        name="your_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.your_name}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start ">
                                                      <label className="form-label">Company Name</label>
                                                      <input
                                                        className="form-control"
                                                        name="comp_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.comp_name}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start mt-4">
                                                  <label className="form-label">Address</label>
                                                  <input
                                                    className="form-control"
                                                    name="address"
                                                    type="text"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.address}
                                                  />
                                                </div>

                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">City*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="city"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.city}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">State*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="state"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.state}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Country*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="country"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.country}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Email id*</label>
                                                      <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.email}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>




                                                <div className="form-group text-start my-4">
                                                  <label className="form-label">Contact No.*</label>
                                                  <input
                                                    className="form-control"
                                                    type="number"
                                                    name="contact"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.contact}
                                                    required
                                                  />
                                                </div>


                                                <div className="text-center">
                                                  <button type="submit" className="mt-4 form-button">Send</button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                  </div>
                                </div>
                              </div>
                            </>
                          )

                        })
                      }
                    </div>
                  </div>
                </div>
                <p className="fs-5 fw-normal mt-5">
                  <b>Ignite<sup>&#174;</sup> </b> tiles are super durable and long-lasting,
                  creating attractiveness & aesthetic appeal in your home for
                  years. They are available in a vast variety of colours,
                  shapes, textures and sizes, easily mimicking other natural
                  materials while maintaining easy-to-clean properties.
                </p>
                <p className="fs-5 fw-normal">
                  Constant innovation and leading technology show our product
                  and approach as a leading tile manufacturer and exporter in
                  India. Check out fabulous designs from our tile collections,
                  adding value to your luxurious lifestyle.
                </p>
              </div>
            </section>

            <Latest_Insights />
          </main>
          <Footer />
        </>
      )}
    </>
  );
}
