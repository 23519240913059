import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import About_Mosaic from "./about-mosaic";
import Catalogue from "./catalogue";
import Latest_Insights from "./get-latest-insights";
import Slider from "./slack-slider";
import { Helmet } from "react-helmet";
import { FaSearch } from "react-icons/fa";
import "@fancyapps/ui/dist/fancybox.css";
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation, Autoplay } from "swiper";
import Navbar from "./navbar";
import Footer from "./footer";
import Loading from "./loading";


const Home = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ignite Ceramic</title>
        <meta
          name="description"
          content="Ignite is a tiles manufacturer, supplier, and exporter. We deal in vitrified tiles, porcelain tiles, swimming pool tiles, subway tiles, GVT & PGVT tiles, full-body tiles, and mosaic tiles. Contact us to know more about the products."
        />
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Navbar modalBg="modalbg" />
          <main id="home">
            <div className="section-1">
              <Swiper
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="wrapper">
                    <img src="previews/2.png" alt="" />
                    <div className="bg-color"></div>
                    <div className="text-wrapper">
                      <h1>INDIA’S LEADING MOSAIC TILES MANUFACTURER</h1>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="wrapper">
                    <img src="previews/1.png" alt="" />
                    <div className="bg-color"></div>
                    <div className="text-wrapper">
                      <h1>SURFACES WITH AN ALLURING STYLE THAT IS UP WITH THE TIMES</h1>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="wrapper">
                    <img src="preview/zed_black_800x2400_15_ss.jpg" alt="" />
                    <div className="bg-color"></div>
                    <div className="text-wrapper">
                      <h1>INDIA’S LEADING PORCELAIN SLAB MANUFACTURER</h1>
                    </div>
                  </div>
                </SwiperSlide>


                <SwiperSlide>
                  <div className="wrapper">
                    <img src="previews/28.jpg" alt="" />
                    <div className="bg-color"></div>
                    <div className="text-wrapper">
                      <h1 className="text-uppercase">Beautiful tiles like never before
</h1>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>

            <section className="section-2 padding-top">
              <div className="container">
                <h2 className="h2_heading text-center">
                  IGNITE LEGACY
                </h2>
                <div className="row mt-5 gx-3">
                  <div className="col-md-4">
                    <div className="col-lg-12">
                      <div className="wrapper">
                        <img
                          src="preview/atlantic_white_800x2400_15_ss.jpg"
                          alt=""
                          style={{ height: "600px", width: "100%" }}

                        />
                        <div className="text-wrapper"
                          data-fancybox="gallery"
                          data-src="preview/atlantic_white_800x2400_15_ss.jpg"
                        >
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="wrapper" style={{ marginTop: "15px" }}>
                        <img
                          src="preview/titenium_beige__1200x1600_15_ss.jpg"
                          alt=""
                          style={{ height: "300px", width: "100%" }}
                        />
                        <div className="text-wrapper"
                          data-fancybox="gallery"
                          data-src="preview/titenium_beige__1200x1600_15_ss.jpg"
                        >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mt-md-0 mt-3">
                    <div className="col-lg-12">
                      <div className="wrapper">
                        <img
                          src="preview/breccia_lava__1200x1600_15_ss.jpg"
                          alt=""
                          style={{ height: "295px", width: "100%" }}
                        />
                        <div className="text-wrapper"
                          data-fancybox="gallery"
                          data-src="preview/breccia_lava__1200x1600_15_ss.jpg"
                        >
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="wrapper" style={{ marginTop: "15px" }}>
                        <img
                          src="preview/craft_hexa_grey_800x2400_15_ss.jpg"
                          alt=""
                          style={{ height: "295px", width: "100%" }}
                        />
                        <div className="text-wrapper"
                          data-fancybox="gallery"
                          data-src="preview/craft_hexa_grey_800x2400_15_ss.jpg"
                        >
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="wrapper" style={{ marginTop: "15px" }}>
                        <img
                          src="preview/dyna_supreme__1200x1600_15_ss.jpg"
                          alt=""
                          style={{ height: "295px", width: "100%" }}
                        />
                        <div className="text-wrapper"
                          data-fancybox="gallery"
                          data-src="preview/dyna_supreme__1200x1600_15_ss.jpg"
                        >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mt-md-0 mt-3">
                    <div className="col-lg-12">
                      <div className="wrapper">
                        <img
                          src="preview/jaisalmer_800x2400_15_ss.jpg"
                          alt=""
                          style={{ height: "300px", width: "100%" }}
                        />
                        <div className="text-wrapper"
                          data-fancybox="gallery"
                          data-src="preview/jaisalmer_800x2400_15_ss.jpg"
                        >
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="wrapper" style={{ marginTop: "15px" }}>
                        <img
                          src="previews/30.jpg"
                          alt=""
                          style={{ height: "600px", width: "100%" }}
                        />
                        <div className="text-wrapper"
                          data-fancybox="gallery"
                          data-src="previews/30.jpg"
                        >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <About_Mosaic />

            <Slider />

            <section className="section-4 padding-top">
              <div className="row gx-0">
                <div
                  className="col-lg-6"
                  style={{ backgroundColor: "#f8f8f8" }}
                >
                  <div className="text-wrapper">
                    <p>
                      When renovating or tiling your space, getting the right
                      tiles from a reliable company is essential. Ignite
                      understands the importance of tiles in interior design. As
                      a result, we provide a wide range of tile solutions so
                      that your space reflects your personality and makes your
                      dream home a beautiful reality.
                    </p>
                    <p className="mb-4">
                      Browse from our extensive collection of different tiles.
                      Or get in touch with us; our experts will gladly help you
                      select the right tiles based on your requirements. Ignite
                      is always here to serve you!
                    </p>
                    <Link to="/contact">
                      <button>REQUEST A CALL</button>
                    </Link>
                    <span className="ms-4">
                      or Give Us A Call On{" "}
                      <a
                        href="tel:+91 99786 24022"
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        +91 99786 24022
                      </a>
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <img src="previews/7.jpg" alt="" />
                </div>
              </div>
            </section>

            <section className="section-5 padding-top">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-4">
                    <h2 className="h2_heading">
                      WHY IGNITE<sup>&#174;</sup>{" "}
                    </h2>
                  </div>
                  <div className="col-lg-7 mt-lg-0 mt-4">
                    <h3>
                      Designer products. Exceptional designs. Visionary tiling
                      solutions.{" "}
                    </h3>
                    <p>
                      Backed by modern-day technology, we have developed a wide
                      range of gorgeous floor and wall tiles using innovative
                      concepts and expertise. Ignite delivers only excellence to
                      its client base in India and around the globe.
                    </p>
                    <p>
                      <b>Ignite<sup>&#174;</sup> </b> firmly believes and acts on its core values
                      of integrity, professionalism, highest quality, and client
                      satisfaction. Every tile produced by our factory is the
                      result of the highest-grade materials, professional input,
                      and rigorous quality checks.
                    </p>
                    <p>
                      Besides delivering exceptionally stunning tiles, Ignite is
                      also known for its supportive customer service. We promise
                      a smooth, hassle-free experience from start to finish,
                      making your tile-selecting journey less of a chore and
                      more of an exciting adventure!
                    </p>
                  </div>
                </div>

                <div className="mt-4">
                  <Swiper
                    // slidesPerView={3}
                    spaceBetween={25}
                    loop={true}
                    autoplay={{
                      delay: 4000,
                      disableOnInteraction: false,
                    }}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 3,
                      },
                    }}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className="image-wrapper">
                        <img
                          src="previews/6.jpg"
                          alt=""
                          data-fancybox="gallery"
                          data-src="previews/6.jpg"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="image-wrapper">
                        <img
                          src="previews/7.jpg"
                          alt=""
                          data-fancybox="gallery"
                          data-src="previews/7.jpg"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="image-wrapper">
                        <img
                          src="previews/8.jpg"
                          alt=""
                          data-fancybox="gallery"
                          data-src="previews/8.jpg"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="image-wrapper">
                        <img
                          src="previews/10.jpg"
                          alt=""
                          data-fancybox="gallery"
                          data-src="previews/3.jpg"
                        />
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="image-wrapper">
                        <img
                          src="previews/11.jpg"
                          alt=""
                          data-fancybox="gallery"
                          data-src="previews/1.png"
                        />
                      </div>
                    </SwiperSlide>


                    <SwiperSlide>
                      <div className="image-wrapper">
                        <img
                          src="previews/12.jpg"
                          alt=""
                          data-fancybox="gallery"
                          data-src="previews/4.jpg"
                        />
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="image-wrapper">
                        <img
                          src="previews/13.jpg"
                          alt=""
                          data-fancybox="gallery"
                          data-src="previews/5.jpg"
                        />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </section>

            <section className="section-6 padding-top">
              <div className="bg-color py-5">
                <div className="container">
                  <h2 className="h2_heading text-center">POPULAR PRODUCTS</h2>
                  <div className="row justify-content-center mt-4">
                    <div className="col-lg-4 col-md-6 mb-5">
                      <Link to='/products' style={{ textDecoration: "none", color: 'inherit' }}>
                        <div className="image-wrapper">
                          <img src="preview/mosaic.jpg" alt="" />
                        </div>
                        <p>Mosaic</p>
                      </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-5">
                      <Link to='/products' style={{ textDecoration: "none", color: 'inherit' }}>
                        <div className="image-wrapper">
                          <img src="preview/porcelaintiles.jpg" alt="" />
                        </div>
                        <p>Porcelain Tiles</p>
                      </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-5">
                      <Link to='/products' style={{ textDecoration: "none", color: 'inherit' }}>
                        <div className="image-wrapper">
                          <img src="preview/porcelainslab.jpg" alt="" />
                        </div>
                        <p>Porcelain Slab</p>
                      </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-5">
                      <Link to='/products' style={{ textDecoration: "none", color: 'inherit' }}>
                        <div className="image-wrapper">
                          <img src="preview/outdoor.jpg" alt="" />
                        </div>
                        <p>Outdoor</p>
                      </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-5">
                      <Link to='/products' style={{ textDecoration: "none", color: 'inherit' }}>
                        <div className="image-wrapper">
                          <img src="preview/sintered.jpg" alt="" />
                        </div>
                        <p>Sintered Stone</p>
                      </Link>
                    </div>


                    <div className="d-flex justify-content-center">
                      <Link to="products">
                        <button className="mt-4">View All</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Catalogue text="padding-top" />

            <section className="testimonials padding-top">
              <div className="container">
                <Swiper
                  navigation={true}
                  loop={true}
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                  }}
                  modules={[Navigation, Autoplay]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <img
                      src="/images/user.jpg"
                      alt=""
                    />
                    <h6>Ankit Yadav</h6>
                    <p>
                      "Such a wide range of choices at a competitive price and
                      first-class customer service…..so friendly, knowledgeable
                      and helpful service. Highly recommended!"
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/images/user.jpg"
                      alt=""
                    />
                    <h6>Jayesh Patel</h6>
                    <p>
                      "I have bought porcelain slab tiles for renovating my
                      home from Ignite and am very delighted with them. Amazing
                      design choices, great quality and fantastic styles never
                      gonna find anywhere else. Well worth choosing them!"
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/images/user.jpg"
                      alt=""
                    />
                    <h6>Rajiv Jivani</h6>
                    <p>
                      "Really helpful. Great collection of quality tiles, good
                      customer service and hassle-free delivery. Looking forward
                      to my next project."
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/images/user.jpg"
                      alt=""
                    />
                    <h6>Damji Patel</h6>
                    <p>
                      "I am so grateful to Ignite for fulfilling my order of Polished Glazed porcelain Tiles so efficiently. An
                      outstanding tile manufacturer & exporter! I admire your
                      professionalism and attention to detail."
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/images/user.jpg"
                      alt=""
                    />
                    <h6>Karsan Patel</h6>
                    <p>
                      "We have received premium quality outdoor tiles for our
                      new bunglow. It helped to get an elegant look with
                      innovative design. Ignite is a reliable tile manufacturer
                      in India and they are known for providing highly durable
                      tiles at the best price."
                    </p>
                  </SwiperSlide>
                </Swiper>
              </div>
            </section>

            <Latest_Insights />
          </main>
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;
