import React from "react";
import { Link } from "react-router-dom";

export default function Latest_Insights() {
    return (
        <section className="get-latest-insights padding-top">
            <div className="container">
                <h2 className="h2_heading text-center">GET LATEST INSIGHTS</h2>
                <div className="row mt-5">
                    <div className="col-lg-6">
                        <Link to="/mosaic-tiles-a-complete-guide" className="link">
                            <div className="image-wrapper">
                                <img src="preview/mosaic.jpg"
                                    alt="" />
                            </div>
                            <div className="text-wrapper mt-4">
                                <h5>Mosaic Tiles: A Complete Guide</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-6">
                        <Link to="/reason-to-choose-porcelain-tiles-for-your-home" className="link">
                            <div className="image-wrapper">
                                <img src="previews/6.jpg"
                                    alt="" />
                            </div>
                            <div className="text-wrapper mt-4">
                                <h5>Reasons To Choose Porcelain Tiles For your Home</h5>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}