import React, { useEffect, useRef, useState } from "react";
import { Link,useLocation  } from "react-router-dom";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaTwitter,
  FaInstagram,
  FaFacebookF,
} from "react-icons/fa";
import Social_Icons from "./social-icons";
import { send } from 'emailjs-com';
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

const Navbar = ({modalBg}) => {
  const [modalClose, setModalClose] = useState(false)
  const [emailData, setEmailData] = useState({
    your_name: '',
    email: '',
    message: ''
  });


  const navigate = useNavigate();


  

  const onSubmit = e => {
    e.preventDefault();
    var form = document.getElementById("myForm");
    if(form.checkValidity()){
      setModalClose(true)
  
        fetch("https://email.ignitemosaic.com/send",{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                name:emailData.your_name,
                email:emailData.email,
                mobile:emailData.number,
                message: emailData.message
            })
        }).then(res=>res.json())
        .then(data=>{
            
            navigate('/thankyou');
        }).catch(err=>{
            console.log(err)
        });
 
    console.log("valid");
    }else{
      console.log("Fill Form")
    }
  //   const templateParams = {
  //     name: emailData.your_name,
  //     email: emailData.email,
  //     message: emailData.message,
  //     mobile:emailData.number
  //   };

  //   send(
  //     'service_eh3ymv8',
  // 'template_5bslte4',
  // templateParams,
  // 'TqdoLKns8KVRsxZKu'
  //   )
  //     .then((response) => {
  //       console.log('SUCCESS!', response.status, response.text);
  //     })
  //     .catch((err) => {
  //       console.log('FAILED...', err);
  //     });
  
     
    // const listElement = useRef(null);

    // handleCloseModal()
  }

  const handleChange = (e) => {
    setEmailData({
      ...emailData,
      [e.target.name]: e.target.value
    });
  };
  return (
    <>
      <header id="header">
        <nav className="navbar navbar-expand-lg nav-bg fixed-top navbar-scroll nav-fixed">
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img src="images/nav-logo.png" alt="logo-image" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNavAltMarkup"
            >
              <div className="navbar-nav">
                <Link to="/company" className="nav-link">
                  Company
                </Link>
                <Link to="/products" className="nav-link">
                  Products
                </Link>
                <Link to="/media" className="nav-link">
                  Media
                </Link>
                {/* <Link to="/download" className="nav-link">
                  Downloads
                </Link> */}
                <Link to="/blogs" className="nav-link">
                  Blogs
                </Link>
                <Link to="/faqs" className="nav-link">
                  Faqs
                </Link>

                <Link to="/contact" className="nav-link">
                  Contact
                </Link>

                {/* <a href="#" href="#" className="nav-link">
                                <div className="pt-4" id='google_translate_element2'></div>
                            </a> */}
                {/* <div id="google_translate_element" /> */}
                {/* <div id="google_translate_element"></div> */}
                <Link to="#" className="nav-link">
                  <button
                    className="nav-button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                 
                  >
                    Get a Quote
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </nav>

        <div
          className={`modal fade thankyou-modal modal-bg ${modalBg}`}
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          // aria-hidden="true"
          // data-bs-backdrop="false"
        >
          <div className="modal-dialog modal-xl modal-fullscreen-lg-down modal-dialog-centered ">
            <div className="modal-content pt-3 pb-5 px-md-5 px-2">
              <div className="modal-header">
                {/* <h1 className="modal-title fs-5" id="exampleModalLabel">Get Listed</h1> */}
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="bg-color">
                  <div className="row d-flex justify-content-center gx-5">
                    <div className="col-xl-5 col-lg-6">
                      <h2>Get a Quote</h2>
                      <p>
                        <b>Ignite<sup>&#174;</sup> </b> is a tile brand in India offering all
                        types of wall and floor tiles for residential,
                        commercial and industrial applications.
                      </p>
                      <div className="wrapper">
                        <p>
                          {" "}
                          <FaPhoneAlt className="me-3 icon" />
                          +91 90331 98246 / +91 99786 24022
                        </p>
                        <p className="py-4">
                          <FaEnvelope className="me-3 icon" />
                          export@ceramiczone.co.in / sales@ignitemosaic.com

                        </p>
                        <p>
                          <FaMapMarkerAlt className="me-3 icon" />
                          1st Floor, Ceramic Zone Complex, Nr Metro Ceramic,
                          Lakhdhirpur Road, Morbi (guj.) India - 363 642
                        </p>
                      </div>
                      <div className="d-flex ">
                        <div className="icon-bg">
                          <Link to="https://www.facebook.com/ignitemosaic/"> <FaFacebookF className="social-icon" /></Link>
                        </div>
                        <div className="icon-bg">
                          <Link to='https://www.instagram.com/ignitemosaic/'> <FaInstagram className="social-icon" /> </Link>
                        </div>
                        <div className="icon-bg">
                          <Link to='https://twitter.com/IgniteMosaic'><FaTwitter className="social-icon" /></Link>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-xl-2 col-0"></div> */}
                    <div className="col-xl-5 col-lg-6 mt-lg-0 mt-4">
                      <div className="form-bg">
                        <form onSubmit={onSubmit} id="myForm">
                          <div className="form-group">
                            <label className="form-label">Your Name</label>
                            <input
                              className="form-control"
                              name="your_name"
                              type="text"
                              placeholder=""
                              onChange={handleChange}
                              value={emailData.your_name}
                              required
                            />
                          </div>
                          <div className="form-group my-4">
                            <label className="form-label">Mail</label>
                            <input
                              className="form-control"
                              type="text"
                              name="email"
                              placeholder=""
                              onChange={handleChange}
                              value={emailData.email}
                              required
                            />
                          </div>
                          <div className="form-group my-4">
                            <label className="form-label">Contact</label>
                            <input
                              className="form-control"
                              name="number"
                              type="tel"
                              id="floatingInput"
                              onChange={handleChange} value={emailData.number}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Message</label>
                            <textarea
                              name="message"
                              id=""
                              cols="30"
                              rows="5"
                              className="form-control"
                              onChange={handleChange}
                              value={emailData.message}
                            ></textarea>
                          </div>
                          {/* <CaptchaTest /> */}

                          {/* <div className="form-group">
                            <input
                              className="form-control"
                              placeholder="Enter Captcha"
                              id="user_captcha_input"
                              name="user_captcha_input"
                              type="text"
                              required
                            ></input>
                            {!captchaErr && (
                              <p>Captcha does Not match</p>
                            )}
                          </div> */}

                          <div className="text-end">
                           
                              <button type="submit" data-bs-dismiss={`${modalClose && 'modal'}`} aria-label={`${modalClose && 'Close'}`} className=" mt-4 form-button">Send</button>
                            {/* <button type="submit" data-bs-dismiss="modal" aria-label="Close" className="mt-4 form-button">Send</button> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Social_Icons />
    </>
  );
};

export default Navbar;
