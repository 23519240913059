import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
  FaPinterestP,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="padding-top">
      <div className="bg-color">
        <div className="container">
          <img src="/images/footer-logo.png" alt="" className="logo-image" />
          <div className="row mt-5 gx-5">
            <div className="col-lg-4">
              <p className="" style={{ fontSize: "16px" }}>
                Ignite is a tile brand in India offering all types of
                wall and floor tiles for residential, commercial and industrial
                applications. From  <b>
                  outdoor, porcelain tiles, porcelain slab, mosaic, sintered
                  stone and ceramic floor & wall tiles
                </b>, and subway tiles to swimming pool tiles, mosaic tiles,
                GVT & PGVT tiles, full-body unglazed porcelain tiles, we offer a wide range of products that suit your tiling
                needs.
              </p>
            </div>
            <div className="col-lg-4">
              <h6>Head Office</h6>
              <address>
                1st Floor, Ceramic Zone Complex, Nr Metro Ceramic, Lakhdhirpur
                Road, Morbi (guj.) India - 363 642
              </address>
              <h6>Factory Unit 1</h6>
              <address>
                Street Of Ambe Way Brige, Lakhdhirpur Road, Morbi (Guj.) India -
                363 642
              </address>
              <h6>Factory Unit 2</h6>
              <address>
                Behind Ceramic Zone Complex, Lakhdhirpur Road, Morbi (guj.)
                India - 363 642
              </address>
            </div>
            <div className="col-lg-4">
              <h6>Links</h6>
              {/* <Link to="/company">Company</Link> */}
              <p>
                <Link to="/company">Company</Link> |{" "}
                <Link to="/products">Products</Link> |{" "}
                <Link to="/media">Media</Link> | <Link to="/blogs">Blogs</Link>{" "}
                | <Link to="/contact">Contact</Link>
              </p>
              <h6>Products</h6>
              <p>
              <Link to="/products">
              Mosaic | Outdoor | Porcelain Tiles | Porcelain Slab | 
               Sintered Stone |
              </Link>
              </p>
              <div className="d-flex mt-4">
                <p className="my-auto">Social</p>
                <div className="social-icons ms-3">
                  <span className="icon-bg ms-0">
                    <Link to="https://www.facebook.com/ignitemosaic/"> <FaFacebookF className="icon" /> </Link> 
                  </span>
                  <span className="icon-bg">
                   <Link to='https://www.instagram.com/ignitemosaic/'> <FaInstagram className="icon" /> </Link>
                  </span>
                  <span className="icon-bg">
                   <Link to='https://twitter.com/IgniteMosaic'><FaTwitter className="icon" /></Link> 
                  </span>
                  
                  <span className="icon-bg">
                   <Link to="https://www.youtube.com/@ignitemosaic4733" > <FaYoutube className="icon" /> </Link>
                  </span>
                  <span className="icon-bg">
                   <Link to='https://www.pinterest.co.kr/ceramiczone/'> <FaPinterestP className="icon" /> </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex mt-5">
            <p>Terms & conditions</p>
            <p className="ms-5">Privacy Policy</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
