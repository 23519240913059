import React from "react"
import Banner from "./banner-section"
import { Helmet } from "react-helmet";


export default function Blog_Inside() {
    return (
        <main id="blog-inside">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Blog-inside</title>
            </Helmet>
            <Banner data="Blog title" />
            <section className="section-2 padding-top">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8 col-11">
                        <h2>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis, molestiae.</h2>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nostrum maxime laudantium. Commodi ullam nihil totam amet veritatis porro omnis nobis quaerat autem animi ratione, enim, laboriosam quis quam ducimus!
                        </p>
                        <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore error hic aliquam reprehenderit voluptatem laborum, asperiores neque? Dolorem cum, necessitatibus eligendi, ratione labore tempore quidem quisquam cupiditate error sit ullam pariatur tenetur enim praesentium voluptas magnam officia obcaecati facere suscipit.
                        </p>
                        <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur.</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore error hic aliquam reprehenderit voluptatem laborum, asperiores neque? Dolorem cum, necessitatibus eligendi, ratione labore tempore quidem quisquam cupiditate error 
                        </p>
                        <h3>1. Lorem ipsum dolor sit amet.</h3>
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad quam et natus soluta non dolore consectetur fugiat inventore quidem in!
                        </p>
                        <h3>2. Lorem ipsum dolor sit amet.</h3>
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad quam et natus soluta non dolore consectetur fugiat inventore quidem in!
                        </p>
                        <h3>3. Lorem ipsum dolor sit amet.</h3>
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad quam et natus soluta non dolore consectetur fugiat inventore quidem in!
                        </p>
                        <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit.   </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore error hic aliquam reprehenderit voluptatem laborum, asperiores neque? Dolorem cum, necessitatibus eligendi, ratione labore tempore quidem quisquam 
                        </p>
                        <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit. lorem12 Lorem, ipsum dolor.</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore error hic aliquam reprehenderit voluptatem laborum, asperiores neque? Dolorem cum, necessitatibus eligendi, ratione labore tempore quidem quisquam cupiditate error sit ullam pariatur tenetur enim praesentium voluptas magnam officia obcaecati facere suscipit. Lorem ipsum dolor sit amet consectetur.
                        </p>
                    </div>
                </div>
            </section>
        </main>
    )
}