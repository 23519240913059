import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Banner from "./banner-section";
import Footer from "./footer";
import Latest_Insights from "./get-latest-insights";
import Loading from "./loading";
import Navbar from "./navbar";

export default function Download() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Download</title>
        <meta
          name="description"
          content="Discover your style to adorn your home beautifully. Download the latest catalogue of Ignite Mosaic for fresh & mind-blowing ideas to design your space."
        />
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Navbar modalBg="modalbg" />
          <main id="download">
            <Banner
              data="Downloads"
              image="preview/mango_onyx_800x2400_15_ss.jpg"
            />

            <section className="section-2 padding-top">
              <h2 className="h2_heading text-center mb-5">
                DOWNLOAD LATEST CATALOGUE
              </h2>
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-4 col-md-6 mb-5">
                    <div className="wrapper">
                      <div className="image-wrapper">
                        <img src="catalogue/800x2400 15mm.jpg" alt="" />
                      </div>
                      <div className="text-center">
                        <h6>800 X 2400 MM</h6>
                        <a
                          href="/download/800x2400mm-15mm-Sintered-Stone.pdf"
                          target="_blank"
                        >
                          <button className="download-btn">Download</button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <div className="wrapper">
                      <div className="image-wrapper">
                        <img src="catalogue/1200x1200 9mm slab.jpg" alt="" />
                      </div>
                      <div className="text-center">
                        <h6>1200 X 1200 MM</h6>
                        <a
                          href="/download/1200X1200mm-9mm-Slab.pdf"
                          target="_blank"
                        >
                          <button className="download-btn">Download</button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <div className="wrapper">
                      <div className="image-wrapper">
                        <img src="catalogue/120x1600 15mm ss.jpg" alt="" />
                      </div>
                      <div className="text-center">
                        <h6>1200 X 1600 MM</h6>
                        <a
                          href="/download/1200X1600mm-15mm-Sintered-Stone.pdf"
                          target="_blank"
                        >
                          <button className="download-btn">Download</button>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 mb-5">
                    <div className="wrapper">
                      <div className="image-wrapper">
                        <img src="catalogue/800x1200 9mm slab.jpg" alt="" />
                      </div>
                      <div className="text-center">
                        <h6>1200 X 1800 MM</h6>
                        <a
                          href="/download/1800X1200mm-9mm-Slab.pdf"
                          target="_blank"
                        >
                          <button className="download-btn">Download</button>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 mb-5">
                    <div className="wrapper">
                      <div className="image-wrapper">
                        <img src="catalogue/1200x2400 9mm slab.jpg" alt="" />
                      </div>
                      <div className="text-center">
                        <h6>1200 X 2400 MM</h6>
                        <a
                          href="/download/1200x2400mm-9mm-Slab.pdf"
                          target="_blank"
                        >
                          <button className="download-btn">Download</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Latest_Insights />
          </main>
          <Footer />
        </>
      )}
    </>
  );
}
