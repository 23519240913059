import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
// import required modules
import { Autoplay } from "swiper";

export default function Social_Icons() {
    const [show, setShow] = useState(false)
    return (
        <>
            <div id="social-icons">
                <div className="wrapper">
                    {
                        show ?
                            (
                                <div className="icons">
                                  <Link to='https://www.instagram.com/ignitemosaic/'>  <div className="social">
                                        <img src="/images/instagram.png" alt="" />
                                        <p>Instagram</p>
                                    </div>
                                    </Link>
                                    <Link to="https://www.facebook.com/ignitemosaic/"> <div className="social">
                                        <img src="/images/facebook.png" alt="" />
                                        <p>Facebook</p>
                                    </div>
                                    </Link>
                                    <Link to="https://www.youtube.com/@ignitemosaic4733" > <div className="social">
                                        <img src="/images/youtube.png" alt="" />
                                        <p>Youtube</p>
                                    </div>
                                    </Link>
                                    <Link to='https://twitter.com/IgniteMosaic'> <div className="social">
                                        <img src="/images/twitter.png" alt="" />
                                        <p>Twitter</p>
                                    </div>
                                    </Link>
                                   <Link to='https://api.whatsapp.com/send/?phone=919033198246&text&type=phone_number&app_absent=0'> <div className="social">
                                        <img src="/images/whatsapp.png" alt="" />
                                        <p>Whatsapp</p>
                                    </div>
                                    </Link>
                                   <Link to='mailto:sales@ignitemosaic.com'> <div className="social">
                                        <img src="/images/mail.png" alt="" />
                                        <p>Mail</p>
                                    </div>
                                    </Link>
                                </div>
                            ) : ""
                    }
                </div>
                <button onClick={() => setShow(!show)}>
                    <Swiper
                    autoplay={{
                        loop:true,
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                     className="mySwiper">
                        <SwiperSlide>
                            <img src="/images/instagram.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/images/facebook.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/images/youtube.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/images/twitter.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/images/linkedin.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/images/mail.png" alt="" />
                        </SwiperSlide>
                    </Swiper>
                </button>
            </div>
        </>
    )
}